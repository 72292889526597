import * as React from "react"

const Bars = () => (
    <div className="bars bars--paused">
        <div className="bar" />
        <div className="bar" />
        <div className="bar" />
        <div className="bar" />
        <div className="bar" />
        <div className="bar" />
        <div className="bar" />
    </div>
)

export default Bars

import * as PropTypes from "prop-types"
import React from "react"
import Show from "./Show"

const ShowList = ({
    shows,
    currentPlaying,
    currentShow,
    setCurrentPlaying
}: any) => (
    <div className="showList">
        {shows.map((show: any) => (
            <Show
                setCurrentPlaying={setCurrentPlaying}
                currentPlaying={currentPlaying}
                currentShow={currentShow}
                key={show.show_id}
                show={show}
            />
        ))}
        <div className="show show--dummy" />
    </div>
)

ShowList.propTypes = {
    shows: PropTypes.array.isRequired,
    currentPlaying: PropTypes.string.isRequired,
    currentShow: PropTypes.string.isRequired,
    setCurrentPlaying: PropTypes.func.isRequired
}

export default ShowList

import { Link } from "gatsby"
import PropTypes from "prop-types"
import * as React from "react"
import { FaPlay } from "react-icons/fa"
import Bars from "./bars"

type Props = {
    show: any
    currentPlaying: string
    currentShow: string
    setCurrentPlaying: (show: number) => void
}

type State = {}

export default class Show extends React.Component<Props, State> {
    static propTypes = {
        show: PropTypes.object.isRequired,
        currentPlaying: PropTypes.string.isRequired,
        currentShow: PropTypes.string.isRequired,
        setCurrentPlaying: PropTypes.func.isRequired
    }

    render() {
        const {
            show,
            currentPlaying,
            currentShow,
            setCurrentPlaying
        } = this.props
        return (
            <div
                className={`show ${
                    currentPlaying === show.show_id ? "show--playing" : ""
                } ${currentShow === show.show_id ? "show--active" : ""}
      `}
            >
                <Link className="show__link" to={`/shows/${show.fields.slug}`}>
                    {/* <p className="show__displayNumber">
                        Episode {show.displayNumber}
                    </p> */}
                    <h3 className="show__title">{show.title}</h3>
                </Link>

                <div className="show__playcontrols">
                    {currentPlaying === show.show_id ? (
                        <Bars />
                    ) : (
                        <button
                            type="button"
                            onClick={() => setCurrentPlaying(show.show_id)}
                            className="show__play"
                            title="play button"
                        >
                            <FaPlay />
                        </button>
                    )}
                </div>
            </div>
        )
    }
}

import * as React from "react"
import { FaFacebookF, FaLinkedin, FaReddit, FaTwitter } from "react-icons/fa"
import {
    FacebookShareButton,
    LinkedinShareButton,
    RedditShareButton,
    TwitterShareButton
} from "react-share"
import "./Share.scss"

const Share = ({ socialConfig, tags, show }) => {
    const url = `https://districtbartalk.com/shows/${show.fields.slug}`
    return (
        <div className="post-social">
            <FacebookShareButton
                url={url}
                className="button is-outlined is-rounded facebook"
            >
                <span className="icon">
                    <FaFacebookF />
                </span>
                <span className="text">Facebook</span>
            </FacebookShareButton>
            <TwitterShareButton
                url={url}
                className="button is-outlined is-rounded twitter"
                title={show.title}
                via={socialConfig.twitterHandle.split("@").join("")}
                hashtags={tags}
            >
                <span className="icon">
                    <FaTwitter />
                </span>
                <span className="text">Twitter</span>
            </TwitterShareButton>
            <LinkedinShareButton
                url={url}
                className="button is-outlined is-rounded linkedin"
                title={show.title}
            >
                <span className="icon">
                    <FaLinkedin />
                </span>
                <span className="text">LinkedIn</span>
            </LinkedinShareButton>
            <RedditShareButton
                url={url}
                className="button is-outlined is-rounded reddit"
                title={show.title}
            >
                <span className="icon">
                    <FaReddit />
                </span>
                <span className="text">Reddit</span>
            </RedditShareButton>
        </div>
    )
}

export default Share

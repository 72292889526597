import { graphql, StaticQuery } from "gatsby"
import * as PropTypes from "prop-types"
import * as React from "react"
import Share from "./Share"

const ShowNotes = ({ show, setCurrentPlaying }: any) => (
    <StaticQuery
        query={graphql`
            query {
                site {
                    siteMetadata {
                        twitterHandle
                        url
                    }
                }
            }
        `}
        render={data => {
            console.log(data)
            return (
                <div className="showNotes">
                    <p className="show__date">{show.published_at}</p>
                    <h2>{show.title}</h2>
                    <button
                        className="button"
                        onClick={() => setCurrentPlaying(show.displayNumber)}
                        type="button"
                    >
                        <span className="icon">🎵</span> Play Episode{" "}
                        {show.displayNumber}
                    </button>
                    <a className="button" download href={show.url}>
                        <span className="icon">👇</span> Download Show
                    </a>
                    <Share
                        socialConfig={{
                            twitterHandle: data.site.siteMetadata.twitterHandle,
                            url: data.site.siteMetadata.url
                        }}
                        tags={[]}
                        show={show}
                    />
                    <div
                        className="notes-html"
                        dangerouslySetInnerHTML={{ __html: show.description }}
                    />
                </div>
            )
        }}
    />
)

ShowNotes.propTypes = {
    show: PropTypes.object.isRequired,
    setCurrentPlaying: PropTypes.func.isRequired
}

export default ShowNotes

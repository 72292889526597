import { graphql } from "gatsby"
import * as React from "react"
import SEO from "../components/seo"
import Page from "../components/Page"
import Player from "../components/Player"
import ShowList from "../components/ShowList"
import ShowNotes from "../components/ShowNotes"

type Props = {
    shows?: any
    data?: any
    pageContext: any
}
type State = {
    currentShow?: any
    currentPlaying?: any
    baseURL?: string
}

class IndexPage extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props)
        const currentShow = props.pageContext.slug
            ? props.data.allShows.edges.find(s => {
                  return s.node.fields.slug === props.pageContext.slug
              }).node.show_id
            : props.data.allShows.edges[0].node.show_id
        this.state = {
            currentShow,
            currentPlaying: currentShow
        }
    }
    setCurrentPlaying = currentPlaying => {
        this.setState({ currentPlaying })
    }
    getShows = () => {
        const { data } = this.props
        return data.allShows.edges.map(d => {
            return { ...d.node }
        })
    }
    render() {
        const { currentShow, currentPlaying } = this.state
        const shows = this.getShows()
        // Currently Shown shownotes
        const show =
            shows.find(showItem => showItem.show_id === currentShow) || shows[0]
        // Currently Playing
        const current =
            shows.find(showItem => showItem.show_id === currentPlaying) ||
            shows[0]
        const description = current.description.replace(/<(?:.|\n)*?>/gm, "")
        return (
            <Page>
                <SEO
                    title={current.title}
                    description={description}
                    image={current.artwork_url}
                    audio_url={current.audio_url}
                />
                <div className="wrapper">
                    <main className="show-wrap" id="main" tab-index="-1">
                        <Player show={current} />
                        <ShowList
                            shows={shows}
                            currentShow={currentShow}
                            currentPlaying={currentPlaying}
                            setCurrentPlaying={this.setCurrentPlaying}
                        />
                        <ShowNotes
                            show={show}
                            setCurrentPlaying={this.setCurrentPlaying}
                        />
                    </main>
                </div>
            </Page>
        )
    }
}

export const query = graphql`
    {
        allShows {
            edges {
                node {
                    id
                    title
                    artist
                    audio_url
                    description
                    explicit
                    duration
                    published_at(formatString: "MMM Do, YYYY")
                    show_id
                    artwork_url
                    fields {
                        slug
                    }
                }
            }
        }
    }
`

export default IndexPage
